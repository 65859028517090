app.product = function() {
    try {
        var $ = jQuery,
            container = document.getElementsByClassName('more-views')[0],
            thumbList = document.getElementsByClassName('product-image-thumbs')[0],
            count = thumbList.childElementCount,
            offset = 0,
            prev,
            next;
            
    } catch(error) {
        console.log('Product Page Script is not required on this page');
        return;
    }
    
    function returnHtml(node, text, className) {
        var element = document.createElement(node);
        element.innerHTML = text;
        element.className = className;
        return element;                        
    }
	
    function getOffset() {
        offset = thumbList.children.length > 1 
                ? thumbList.children[1].offsetTop - thumbList.children[0].offsetTop 
                : 0;
    }
    
    function appendButtons() {
        // ADD BUTTONS FOR NAV 
        prev = returnHtml('a', 'o', 'product-image-thumb--prev');   
        next = returnHtml('a', 'r', 'product-image-thumb--next');
        container.appendChild(next);
        container.appendChild(prev);
    }
    
    function advanceMenu(e) {
        var top,
            offsetUpdate;
        
        e.preventDefault();
        e.stopPropagation();
        if (thumbList.style.top !== null && thumbList.style.top !== '' ) {
            top = Number.parseInt(thumbList.style.top.replace('px', ''));
        } else {
            top = 0;            
        }
        
        if ( e.target === next ) {
            console.log(top);
            if (top <= -offset * 2) {
                return;
            }
            offsetUpdate = top - offset;
            thumbList.style.top = offsetUpdate + 'px'; 
        } else {
            if (top >= 0) {
                return;
            }
            // IF NO MORE SPACE MOVE DOWN A FEW PIXELS AND BACK     
            
            offsetUpdate = top + offset;
            thumbList.style.top = offsetUpdate + 'px';
        }
    }
    
    thumbList.style.top = '0px';
    document.getElementsByClassName('more-views__viewbox')[0].style.overflow = 'hidden';
    
    // SHOW NAVIGATION
    appendButtons();
    
    // FIND OFFSET BETWEEN IMAGES
    getOffset();
    
    // ON CLICK CHANGE POSITION UP OR DOWN BY OFFSET
    container.addEventListener('click', advanceMenu);

};