app.hero = function($) {
    var wrapper = $('.hero-wrapper'),
        slides = [],
        slideDuration = 6000,
        Slide = function(img, title, text, node) {
            var self = {
                'img': img || null,
                'title': title || null,
                'text': text || null,
                'node': node || null
            };
            return self;
        },
        Hero = function(slides, current) {
            var self = {
                'slides': slides,
                'current': current || 0
            };
            return self;
        },
        hero;
        
    
    function initSlides() {
        jQuery('.hero-wrapper .hero').each(function(index){
            var self = this,
                image = $(self).find('img').attr('src'),
                text = $(self).find('.hero__cta'),
                title = $(self).find('.hero__title');
            slides.push(Slide(image, title, text, self));
        });
    }
    function setBackground() {
        slides.forEach(function(slide) {
            slide.node.style.backgroundImage = 'url(' + slide.img + ')'; 
        });
    }
    function initNav() {
        var prev,
            next,
            iconArr = [];
        iconArr.push(prev);
        iconArr.push(next);
        function createIcons(iconArr) {
            var classes = [{
                    class: 'prev',
                    content: 'u'
                }, {
                    class: 'next',
                    content: 't'
                }];
            iconArr.forEach(function(icon, index) {
                icon = document.createElement('span');
                icon.className = 'hero__icon';
                icon.className += ' ' + classes[index].class;
                icon.innerHTML = classes[index].content;
                wrapper.append(icon);
            });
        }    
        createIcons(iconArr);
        hero = Hero(slides, 0);
        hero.slides[0].node.className += ' current';
    }
    function advanceSlide(direction) {
        hero.slides[hero.current].node.className = 'hero';
        if (direction === 'next') {
            hero.current = (hero.current + 1) % hero.slides.length;
        } else {
            hero.current = (hero.current - 1 + hero.slides.length) % hero.slides.length;
        }
        hero.slides[hero.current].node.className = 'hero current';
    }
    function processEvents() {
        $('.hero__icon').click(function() {
            var self = this,
                direction = self.className.match(/prev|next/)[0];
                advanceSlide(direction);
        });
        $('.hero-wrapper').mouseenter(function() {
           hero.pause = true; 
        }).mouseleave(function() {
           hero.pause = false; 
        });
    }
    
    function autoIncrement() {
        window.setTimeout(function() {
            autoIncrement();
            if (!hero.pause) {
                advanceSlide('next');
            }
        }, slideDuration);
    }
    
    function initHero() {        
        initSlides();
        setBackground();
        initNav();
        processEvents();
        autoIncrement();
    }
    initHero();
};