app.showSearch = function() {
    var $ = jQuery,
        searchBox = document.getElementsByClassName('UI-SEARCH')[0];
    // simple function to show search in header on click
        // if (searchBox !== undefined && searchBox !== null) {
        //     searchBox.addEventListener('click', function(e) {
        //         e.stopPropagation();
        //         searchBox.placeholder = '';
        //     });
        // }

    //    

};