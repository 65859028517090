app.sticky = function($) {
    var container = document.getElementsByClassName('main-container')[0],
        sticky = document.getElementsByClassName('sticky-contact')[0],
        trigger,
        bottomContent = false;
    window.addEventListener('scroll', function() {
        trigger = container.offsetHeight - window.scrollY - 200;
        if (trigger <= 0 && !bottomContent) {
            sticky.className += ' bottom-content';
            bottomContent = !bottomContent;
        } else if (trigger > 0 && bottomContent) {
            // $(sticky).removeClass('bottom-content');
            sticky.className = sticky.className.replace(/\sbottom-content/, '');
            bottomContent = !bottomContent;
        } 
    });
};