app.mobileNav = function() {
    var $ = jQuery,
            nav = $('#header-nav').clone(),
            active = false,
            navReady = false;
            
    nav.attr('id', 'mobile-nav');
    nav.addClass('mobile-nav');

    function getNav() {
        $('.wrapper').append(nav);   
    }
    function toggleNav(e) {
        e.stopPropagation();
        if (!active) {
            $('.page').addClass('mobile-nav-active');
            $('.page').click(toggleNav);
        } else {
            $('.page').removeClass('mobile-nav-active');
            $('.page').off('click', toggleNav);
        }
        if(!navReady) {
            getNav();
            navReady = true;
        }
        active = !active;
    }
    $('.skip-nav').click(toggleNav); 
};