app.init = function($) {
    // do something once page is loaded
    $('.cms-home').length ? app.hero($) : app.sticky($);
    
    app.showSearch();  
    app.mobileNav();
    app.brochures();
};

jQuery(function() {
    // When the time is right, let's fire it up
    app.init(jQuery); 
});