app.brochures = function() {
    jQuery(".expandable-hidden").css("display", "none");
    jQuery(".expandable").unbind('click').click(function(e){
        var toggle =  jQuery(this).closest('.expandable');
        console.log ('expanded');
        toggle.find('.expandable-visible').toggleClass('expandable-open');
	toggle.find('.expandable-hidden').slideToggle(300);

        //jQuery(".expandable").closest(".expandable-hidden").slideToggle(300);

    });
};